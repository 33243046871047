html {
  scroll-behavior: smooth;
  scrollbar-width: none;
}
html.o-h {
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: transparent;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
img {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
*::-moz-selection {
  background: #18484c;
}
*::selection {
  background: #18484c;
}
*::-webkit-scrollbar {
  width: 0.3rem;
}
*::-webkit-scrollbar-track {
  background: #14232e;
}
*::-webkit-scrollbar-thumb {
  background: #1f2934;
  border-radius: 0.625rem;
}
*::-webkit-scrollbar-thumb:hover {
  background: #b7b7b7;
}
ol,
ul {
  list-style: none;
}
blockquote {
  quotes: none;
}
blockquote:before,
blockquote:after {
  content: "";
  content: none;
}
del {
  text-decoration: line-through;
}
body {
  font-family: "baloo_paaji_2regular";
  font-size: 1.0625rem;
  color: #333;
  overflow-x: hidden;
  min-width: 20rem;
  position: relative;
  background: #14232e;
}
input,
textarea,
select {
  font-family: "baloo_paaji_2regular";
  background: unset;
  border: unset;
}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
*:focus {
  outline: none;
}
a {
  color: #333;
}
.submit:hover {
  filter: alpha(opacity=85);
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
.thumb {
  display: block;
}
.thumb img {
  display: block;
  width: 100%;
}
p {
  line-height: 1.6em;
  font-size: 1rem;
  font-family: "baloo_paaji_2regular";
}
br.clear {
  clear: both;
  line-height: 0;
}
.anim-fade {
  animation: fade 330ms ease-in-out 0ms;
}

@font-face {
  font-family: "baloo_paaji_2semibold";
  src: url("../fonts/semibold-webfont.woff2") format("woff2"),
    url("../fonts/semibold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "baloo_paaji_2regular";
  src: url("../fonts/regular-webfont.woff2") format("woff2"),
    url("../fonts/regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "baloo_paaji_2medium";
  src: url("../fonts/medium-webfont.woff2") format("woff2"),
    url("../fonts/medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "baloo_paaji_2bold";
  src: url("../fonts/bold-webfont.woff2") format("woff2"),
    url("../fonts/bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.bold {
  font-family: "baloo_paaji_2bold";
}
.medium {
  font-family: "baloo_paaji_2medium";
}
.semi-bold {
  font-family: "baloo_paaji_2semibold";
}
.white {
  color: #fff;
}
.black {
  color: #000;
}
.d-f {
  display: flex;
}
.f-d-c {
  flex-direction: column;
}
.a-c {
  align-items: center;
}
.j-c {
  justify-content: center;
}
.j-s-b {
  justify-content: space-between;
}
.pointer {
  cursor: pointer;
}
.d-b {
  display: block;
}
.d-i-b {
  display: inline-block;
}

#talrop-care {
  background: #14232e;
  height: -webkit-fill-available;
}
header {
  background: #1f2934;
  height: 67px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
}
/* header.shrink.responsive .container {
    left: 185px;
}
header.shrink .container {
    left: 67px;
} */
header .container {
  height: 67px;
  display: flex;
  align-items: center;
  padding: 0 21px;
  justify-content: space-between;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  left: 260px;
}
header.shrink .container {
  left: 72px;
}
/* header.outer-router-shrink .container {
    left: 85px;
} */
header .left,
header .right {
  display: flex;
  align-items: center;
}
header .left .menu-icon {
  margin-right: 49px;
  cursor: pointer;
}
header .left .menu-icon img {
  width: 18px;
}
header .left .search-bar {
  background: #14232e;
  padding: 9px 22px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  width: 330px;
}
header .left .search-bar input {
  color: #fff;
  font-size: 19px;
  margin-left: 12px;
  width: 100%;
}
header .left .search-bar input::placeholder {
  color: #fff;
  font-size: 19px;
}
header .right {
}
header .right .icons {
  display: flex;
  align-items: center;
  padding: 5px 25px 5px 0;
  border-right: 1px solid #444b53;
}
header .right .icons b {
  font-size: 10px;
  font-family: "product_sansbold";
  position: absolute;
  display: flex;
  background: #f51561;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  top: -7px;
  right: -7px;
  color: #fff;
  width: 15px;
  height: 15px;
}
header .right .icons img {
  width: 100%;
  margin: 0 auto;
}
header .right .icon {
  max-width: 20px;
  margin-right: 25px;
}
header .right .icon:last-child {
  margin-right: 0;
}
header .right .profile-header-card {
  display: flex;
  align-items: center;
  padding-left: 25px;
  position: relative;
}
header .right .profile-header-card .profile-img {
  height: 39px;
  width: 39px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-right: 12px;
  border: 1px solid #444b53;
}
header .right .profile-header-card .name {
  display: inline-block;
  font-size: 16px;
  margin-right: 7px;
  transform: translateY(-1px);
}
header .right .profile-header-card img.arrow {
  width: 8px;
}
/* first menu  starts*/

/* aside .menu-items {
    border-top: 2px solid rgb(92, 198, 106);
} */

aside.main-menu {
  position: fixed;
  z-index: 100;
  top: 0px;
  bottom: 0;
  width: 60px;
  left: 0;
  background: #1f2934;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  transition: width 0.3s ease-in-out;
}
aside.main-menu.xl-large {
  width: 260px;
}
aside.main-menu.xl-large.inner {
  width: 230px;
}
aside.main-menu.xl-large.m-shrink {
  width: 72px;
}
aside.main-menu.xl-small {
  width: 72px;
}
aside.main-menu.xl-small.m-expand {
  width: 260px;
}
aside.main-menu.xl-small.m-expand .title-box {
  display: block;
}
aside.main-menu.xl-small.m-expand .menu-item {
  transition: display 1s;
  white-space: nowrap;
  padding: 0 25px;
}
aside.main-menu.xl-small .title-box,
aside.main-menu.xl-large.m-shrink .title-box {
  display: none;
}
aside.main-menu.xl-small .menu-item,
aside.main-menu.xl-large.m-shrink .menu-item {
  padding: 0;
  justify-content: center;
}

#wrapper.shrink {
  margin-left: 72px;
}

/* aside.main-menu.inner-shrink {
    width: 57px;
}
aside.main-menu.first-menu.inner-shrink .title-box {
    display: none;
}
aside.main-menu.first-menu.inner-shrink .menu-item {
    padding: 0;
    justify-content: center;
} */
/* aside.main-menu.shrink {
    width: 67px;
} */
aside.main-menu::-webkit-scrollbar {
  width: 0;
}
aside.main-menu h1 {
  width: 260px;
  height: 67px;
  padding: 0 25px;
  display: flex;
  align-items: center;
}
aside.main-menu h1 img {
  width: 135px;
  height: 35px;
}
aside.main-menu .menu-item {
  padding: 0 25px;
  position: relative;
  height: 52px;
  justify-content: flex-start;
}
aside.main-menu .menu-item img.icon-img {
  max-width: 18px;
  max-height: 18px;
}
aside.main-menu .menu-icon {
  justify-content: center;
  align-items: center;
  width: 20px;
}
aside.main-menu .item-container.selected .menu-item {
  background: #14232e;
}
aside.main-menu .item-container.selected .menu-item::before {
  position: absolute;
  left: 0;
  width: 5px;
  background: #5cc66a;
  content: "";
  top: 0;
  bottom: 0;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
aside.main-menu .item-container.selected .menu-item::before .menu-icon {
  position: absolute;
  left: 0;
  width: 5px;
  background: #5cc66a;
  content: "";
  top: 0;
  bottom: 0;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
aside.main-menu .menu-icon .icon-img {
  width: 18px;
}
aside.main-menu .title-box {
  width: -webkit-fill-available;
  width: -moz-available;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
aside.main-menu .title {
  font-size: 16px;
  display: inline-block;
}
aside.main-menu .arrow {
  transition: 0.4s ease-in-out;
  width: 7px;
}
aside.main-menu .drop-down-menu {
  display: none;
  background: #14232e;
  padding: 8px 0;
}
aside.main-menu .drop-down-menu .drop-down-item {
  padding: 8px 32px;
}
aside.main-menu .drop-down-menu .drop-down-item .round {
  height: 5px;
  display: inline-block;
  width: 5px;
  border-radius: 50%;
  border: 2px solid #717782;
  margin-right: 15px;
}
aside.main-menu .drop-down-menu .drop-down-item .drop-down-item-text {
  font-size: 16px;
  color: #717782;
}
aside.main-menu .drop-down-menu .drop-down-item.active .drop-down-item-text {
  color: #fff;
}
aside.main-menu .drop-down-menu .drop-down-item.active .round {
  border-color: #fff;
  background: #fff;
}
aside.main-menu .item-container.d-active .arrow {
  transform: rotate(90deg);
}
/* first menu ends */
aside.main-menu.second-menu {
  /* display: none; */
  z-index: 50;
  left: 67px;
  top: 66px;
  border-right: 1px solid #1f2934;
  background: #13232e;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
aside.main-menu.second-menu.xl-large {
  width: 229px;
}
aside.main-menu.second-menu.xl-small {
  width: 70px;
  left: 230px;
}
aside.main-menu.second-menu.xl-small .title-box {
  display: none;
}
aside.main-menu.second-menu.xl-small .arrow {
  display: none;
}
aside.main-menu.second-menu.hidden {
  display: none;
}
aside.main-menu.second-menu::-webkit-scrollbar {
  width: 0;
}
aside.main-menu.second-menu.active {
  display: block;
  width: 258px;
  transition: 0.3s ease-in-out;
}
aside.main-menu.second-menu .item-container.selected .menu-item::before {
  left: unset;
  right: 0;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
aside.main-menu.second-menu .item-container.selected .menu-item {
  background: #1f2934;
}
aside.main-menu.first-menu .title-box {
  transition: display 1s;
  white-space: nowrap;
}
/* aside.main-menu.first-menu.shrink .title-box {
    display: none;
} */
#wrapper {
  padding-top: 67px;
  transition: margin 0.3s ease-in-out;
  margin-left: 260px;
  position: unset !important;
}
#wrapper.inner {
  margin-left: 300px;
}
#wrapper.inner.expand {
  margin-left: 260px;
}
/* #wrapper.inner-shrink {
    margin-left: 297px;
} */
#main {
  position: unset !important;
}
#main .main-container {
}
.messages.move div {
  animation: slideUp 0.167s cubic-bezier(0.4, 0, 0.2, 1);
}
.messages.move div:last-child {
  animation: slideUpAndRight 0.167s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideUp {
  from {
    transform: translateY(44px);
  }
  to {
    transform: none;
  }
}

@keyframes slideUpAndRight {
  from {
    transform: translateX(-100px) translateY(44px);
  }
  to {
    transform: none;
  }
}

.messages div {
  font-size: 16px;
  padding: 14px 19px;
  background: #55c597;
  margin-bottom: 6px;
  border-radius: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  max-width: 455px;
}
.messages div:last-child {
  margin-bottom: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 25px;
}
.messages div:first-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 25px;
}

.audio-player-volume {
  display: none !important;
}

input[type="range"].invert-blue-grey {
  -webkit-appearance: none;
  width: 100%;
  margin: 5px 0;
}
input[type="range"].invert-blue-grey:focus {
  outline: none;
}
input[type="range"].invert-blue-grey::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #137ee7;
  border-radius: 6px;
}
input[type="range"].invert-blue-grey::-webkit-slider-thumb {
  height: 15px;
  border: 1px solid #a1a1a1;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"].invert-blue-grey::-moz-range-thumb {
  height: 15px;
  border: 1px solid #a1a1a1;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"].invert-blue-grey::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #137ee7;
  border-radius: 6px;
}
#play {
  cursor: pointer;
  margin-right: 10px;
}
.audio-player-time {
  color: #989898;
  font-family: "baloo_paaji_2regular";
  position: absolute;
  right: 0;
  top: 26px;
  font-size: 12px;
}
.audio-player {
  position: relative !important;
}
.audio-player-seeker {
  width: 95% !important;
}
.__react_modal_image__medium_img {
  max-width: 70% !important;
  max-height: 70% !important;
}
.__react_modal_image__modal_container {
  /* position: absolute !important; */
}

/* audio player starts */
.audio-msg {
  min-width: 350px;
}

/* responsive starts */
@media all and (max-width: 1440px) {
  header .left .menu-icon {
    display: block;
  }
  /* aside.main-menu {
        width: 262px;
    }
    #wrapper {
        margin-left: 262px;
    } */
}
@media all and (max-width: 1279px) {
  header .container {
    left: 72px;
  }
  aside.main-menu.first-menu.md-small {
    width: 61px !important;
  }
  aside.main-menu.first-menu.md-small.m-expand {
    width: 242px;
    padding: 0 25px;
  }
  aside.main-menu.first-menu.md-small.m-expand .title-box {
    display: block;
  }
  aside.main-menu.first-menu.md-small.m-expand .title-box {
    transition: display 1s;
    white-space: nowrap;
  }
  aside.main-menu.second-menu.md-large {
    width: 231px;
  }
  aside.main-menu.second-menu.md-small {
    width: 61px;
  }
  aside.main-menu.second-menu.md-small .title-box {
    display: none;
  }
  aside.main-menu.second-menu.md-small .arrow {
    display: none;
  }
  aside.main-menu.second-menu.md-small.m-expand {
    width: 200px;
  }
  aside.main-menu.second-menu.md-small.m-expand .menu-item {
    padding: 0 25px;
  }
  aside.main-menu.second-menu.md-small.m-expand .title-box {
    display: block;
  }
  aside.main-menu.second-menu.md-small.m-expand .arrow {
    display: block;
  }
  #wrapper {
    margin-left: 61px;
  }
  #wrapper.inner {
    margin-left: 122px;
  }
  aside.main-menu .title {
    font-size: 15px;
  }
  aside.main-menu .menu-item {
    height: 48px;
  }
  aside.main-menu .drop-down-menu .drop-down-item .drop-down-item-text {
    font-size: 15px;
  }
  aside.main-menu .drop-down-menu .drop-down-item {
    padding: 8px 32px;
  }
}
@media (max-width: 1279px) {
  aside.main-menu h1 {
    padding: 0 14px;
  }
  aside.main-menu.second-menu {
    left: 61px;
  }
  aside.main-menu.second-menu.shrink .title-box {
    display: none;
  }
  aside.main-menu.second-menu.shrink .menu-item {
    padding: 0;
    justify-content: center;
  }
  aside.main-menu.second-menu.shrink .arrow {
    display: none;
  }
  /* aside.main-menu.second-menu.expand .title-box {
        display: block;
    }
    aside.main-menu.second-menu.expand {
        width: 210px;
    }
    aside.main-menu.second-menu.shrink {
        width: 57px;
    }
    aside.main-menu.second-menu.shrink .menu-item {
        padding: 0;
        justify-content: center;
    }
    aside.main-menu.second-menu.shrink .arrow {
        display: none;
    } */

  /* new */
  /* aside.main-menu.shrink {
        width: 57px;
    }
    aside.main-menu.first-menu.shrink .title-box {
        display: none;
    }
    #wrapper.inner-shrink {
        margin-left: 121px;
    }
    aside.main-menu.expand {
        width: 196px;
    }
    header.shrink.expand {
        left: 189px;
    }
    aside.main-menu.first-menu.shrink .menu-item {
        padding: 0;
        justify-content: center;
    } */
}
@media all and (max-width: 768px) {
  aside.main-menu.sm-hidden {
    left: -51px;
    width: 51px;
  }
  aside.main-menu.first-menu.sm-small {
    left: 0;
    width: 51px;
  }
  aside.main-menu.sm-small .menu-item {
    padding: 0;
    justify-content: center;
  }
  aside.main-menu.first-menu.sm-small .title-box {
    display: none;
  }
  aside.main-menu.sm-large {
    width: 196px;
  }
  aside.main-menu.sm-large {
    width: 196px;
  }
  aside.main-menu.first-menu.sm-large .title-box {
    display: block;
  }
  aside.main-menu.first-menu.sm-large .title-box {
    transition: display 1s;
    white-space: nowrap;
  }
  aside.main-menu.second-menu.sm-small {
    left: 51px;
    width: 51px;
  }
  aside.main-menu.second-menu.sm-large {
    left: 51px;
  }
  aside.main-menu.second-menu.sm-small .menu-item {
    padding: 0;
    justify-content: center;
  }
  aside.main-menu.second-menu.sm-small .title-box {
    display: none;
  }
  aside.main-menu.second-menu.sm-small .arrow {
    display: none;
  }
  aside.main-menu.second-menu.sm-large .menu-item {
    padding: 0 25px;
  }
  aside.main-menu.second-menu.sm-large .title-box {
    display: block;
  }
  aside.main-menu.second-menu.sm-large .arrow {
    display: block;
  }
  aside.main-menu h1 img {
    width: 127px;
  }
  /* aside.main-menu.second-menu.responsive-shrink {
        left: 196px;
    }
    aside.main-menu.second-menu.responsive-expand {
        width: 191px;
        left: 56px;
    } */
  /* header.shrink .container {
        left: 0;
    }
    #wrapper.shrink {
        margin-left: 0;
    } */
  aside.main-menu {
    /* display: none; */
  }
  #wrapper {
    margin-left: 0;
  }
  #wrapper.inner {
    margin-left: 0;
  }
  #wrapper.shrink {
    margin-left: 0;
  }
  #wrapper.shrink.expand {
    margin-left: 0;
  }
  header .container {
    left: 0;
  }
  header.shrink .container {
    left: 0;
  }
  header.shrink.expand .container {
    left: 0;
  }
  header .right .profile-header-card .name,
  header .right .profile-header-card img.arrow {
    display: none;
  }
  header .right .profile-header-card .profile-img {
    margin-right: 0;
  }

  /* new */
  aside.main-menu.hidden {
    left: -196px;
  }
  /* aside.main-menu.shrink {
        left: 0;
        width: 51px;
    }
    aside.main-menu.visible-shrink .title-box {
        display: none;
    }
    aside.main-menu.visible-shrink .menu-item {
        padding: 0;
        justify-content: center;
    }
    aside.main-menu.second-menu.visible-shrink {
        left: 51px;
        width: 51px;
    }
    aside.main-menu.second-menu.visible-shrink .menu-item {
        padding: 0;
        justify-content: center;
    }
    aside.main-menu.second-menu.visible-shrink .title-box {
        display: none;
    }
    aside.main-menu.second-menu.visible-shrink .arrow {
        display: none;
    }
    @media (max-width: 1279px) {
        aside.main-menu.second-menu.visible-expand {
            width: 180px;
            left: 51px;
        }
    } */
}
@media all and (max-width: 480px) {
  header,
  header .container {
    height: 60px;
  }
  header .right .icon {
    margin-right: 20px;
    max-width: 18px;
  }
  header .right .icons {
    padding-right: 20px;
  }
}

.audio-player-volume {
  display: none !important;
}

input[type="range"].invert-blue-grey {
  -webkit-appearance: none;
  width: 100%;
  margin: 5px 0;
}
input[type="range"].invert-blue-grey:focus {
  outline: none;
}
input[type="range"].invert-blue-grey::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #137ee7;
  border-radius: 6px;
}
input[type="range"].invert-blue-grey::-webkit-slider-thumb {
  height: 15px;
  border: 1px solid #a1a1a1;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"].invert-blue-grey::-moz-range-thumb {
  height: 15px;
  border: 1px solid #a1a1a1;
  width: 15px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"].invert-blue-grey::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #137ee7;
  border-radius: 6px;
}
#play {
  cursor: pointer;
  margin-right: 10px;
}
.audio-player-time {
  color: #989898;
  font-family: "product_sansregular";
  position: absolute;
  right: 0;
  top: 26px;
  font-size: 12px;
}
.audio-player {
  position: relative !important;
}
.audio-player-seeker {
  width: 95% !important;
}
.__react_modal_image__medium_img {
  max-width: 70% !important;
  max-height: 70% !important;
}
.__react_modal_image__modal_container {
  /* position: absolute !important; */
}
.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

/* audio player starts */
.audio-msg {
  min-width: 310px;
}
@media only screen and (max-width: 480px) {
  .audio-msg {
    min-width: 258px;
  }
  .aud-action {
    width: 33px;
  }
}
.rhap_repeat-button,
.rhap_additional-controls,
.rhap_volume-button,
.rhap_volume-bar-area,
.rhap_volume-container {
  display: none !important;
}
.rhap_controls-section {
  flex: unset !important;
}
.rhap_container {
  padding: 0 !important;
  box-shadow: unset !important;
  background-color: unset !important;
}
.rhap_time {
  color: #fff !important;
  font-size: 12px !important;
}
.rhap_progress-indicator,
.rhap_progress-filled {
  background: #fff !important;
}
.audio-msg.inc .rhap_time {
  color: #656565 !important;
}
audio-msg.inc .rhap_progress-indicator,
.rhap_progress-filled {
  background: #147ee7 !important;
}
.aud-action {
  display: block;
  width: 38px;
}

/* PAGINATION */

.pagination {
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-wrap: wrap;
}
.pagination a {
  padding: 4px 6px;
  height: 35px;
  min-width: 35px;

  border-radius: 5px;
  color: #f8f8fc;
  background-color: #14232e;
  border: 1px solid #ffffff3d;
  font-family: "baloo_paaji_2semibold";
  margin: 0 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease 0.4s;
}
.pagination li.break a {
  display: inline-block;
}
.previous .pagination__link {
  background-color: unset;
  color: #fff;
  font-size: 16px;
  font-family: "baloo_paaji_2medium";
  border: unset;
}
.next .pagination__link {
  background-color: unset;
  color: #fff;
  font-size: 16px;
  font-family: "baloo_paaji_2medium";
  border: unset;
}
.pagination__link {
  font-weight: bold;
}
.pagination__link--active a {
  color: #fff;
  background: #15bf81;
}
.pagination__link--disabled a {
  cursor: pointer;
}
.active_link_classname {
  color: #fff;
  background: #15bf81;
}

/* PAGINATION ENDS*/
